.empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.empty p {
  margin-top: 20px;
  color: var(--td-danger) !important;
}
