header {
  background-color: var(--td-white);
  height: 50px;
  margin-bottom: 0px;
  position: sticky;
  top: 0px;
  padding: var(--td-padding-main);
  box-shadow: var(--td-box-shadow);
  display: flex;
  align-items: center;
  /* z-index: 10; */
}
header > div:nth-child(1) {
  flex: 1;
}
header > div:nth-child(2) {
  display: flex;
  align-items: center;
}
header > div h1 {
  font-size: 1.4rem;
  font-weight: bolder;
  color: var(--td-success);
  animation: enter-text-left-to-right 1s;

  display: flex;
  align-items: center;
}
header > div h1::before {
  content: "";
  display: inline-block;
  width: 5px;
  height: 40px;
  background-color: var(--td-primary);
  margin-right: 10px;
}
header .notifications {
  /* background-color: var(--td-info); */
  position: relative;
  padding: 10px;
  border-radius: 50px;
  margin-right: 20px;
}
header .notifications span {
  position: absolute;
  top: 0;
  left: 27px;
  background-color: var(--td-danger);
  color: var(--td-white);
  border-radius: 20px;
  width: 15px;
  height: 15px;
  text-align: center;
  font-size: 0.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
header .avatar {
  display: flex;
  align-items: center;
}
header .avatar figure {
  width: 40px;
  height: 40px;
}
header .avatar figure img {
  width: 100%;
}
header .avatar .identity {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  text-align: center;
}
header .avatar .identity span:nth-child(1) {
  font-weight: bolder;
  color: var(--td-success);
}
header .avatar .identity span:nth-child(2) {
  font-size: 0.8rem;
}

/* ##################################### */
/* ##################################### */
/* ##################################### */
/* ######## HEADER STYLE V1 ############# */
/* ##################################### */
/* ##################################### */
/* ##################################### */
.v1 header {
  height: 200px;
  background-color: var(--td-primary);
}
.v1 header > div h1 {
  color: var(--td-white);
}
.v1 header .avatar .identity span:nth-child(1),
.v1 header .avatar .identity span:nth-child(2) {
  color: var(--td-white);
}
