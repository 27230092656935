.login {
  min-height: 100vh;
  height: 100%;
  display: flex;
}
.login > div {
  flex: 1;
}
.login > div:nth-child(1) {
}

.login > div:nth-child(2) {
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: var(--td-primary); */
}
.login div {
  position: relative;
}
.login div .title-login {
  margin-left: 80px;
  margin-top: 6rem;
  display: flex;
}
.login div .title-login h1 {
  font-weight: bolder;
  color: var(--td-primary);
  text-transform: uppercase;
  font-size: 1.4rem;
}
.login div .container-login {
  margin-top: 20px;
  margin-left: 80px;
}
.login div .container-login > div {
  margin-top: 10px;
}
.login div .container-login h2 {
  margin-bottom: 10px;
  font-weight: bolder;
  font-size: 1rem;
  text-transform: uppercase;
}
.login form {
  width: 60%;
}

/* ##################################### */
/* ##################################### */
/* ##################################### */
/* ######## LOGIN STYLE V1 ############# */
/* ##################################### */
/* ##################################### */
/* ##################################### */
.v1 .login {
  display: flex;
  align-items: center;
  /* background-color: var(--td-light); */
  background-image: url("../../assets/images/bg_log_v1.jpg");
}
.v1 .login > div {
  background-color: var(--td-white);
}
.v1 .login > div:nth-child(1) {
  flex: 0 1 40%;
  margin: 0 auto;
  min-height: 500px;
  height: 500px;
  box-shadow: var(--td-box-shadow);
  border-radius: var(--td-border-radius);
}
.v1 .login > div:nth-child(2) {
  display: none;
}
.v1 .login div .title-login {
  margin-left: 0px;
  padding: 0px 100px;
}
.v1 .login div .container-login {
  margin-left: 0px;
  padding: 0px 100px;
}
.v1 .login form {
  width: 100%;
}
