.ted-input {
  border: 2px solid var(--td-primary);
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  position: relative;
  border-radius: var(--td-border-radius);
  padding: 2px;
  flex-direction: row;
  /* overflow: hidden; */
}

.ted-input .icons {
  /* border: 1px solid blue; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
}

input[type="text"],
input[type="password"],
input[type="date"],
input[type="number"],
textarea {
  border: 0px solid;
  outline: 0px;
  padding: 15px 10px;
  font-family: var(--td-font-regular);
  flex: 1;
}
input::placeholder {
  font-family: var(--td-font-regular);
  padding-left: 10px;
}

/* input:valid + label */
input[type="text"]:focus + label,
input[type="password"]:focus + label,
input:not([value=""]) + label,
input[type="date"] + label,
textarea:focus + label,
textarea:not([value=""]) + label,
.ted-input .select.float + label {
  top: 0;
  color: var(--td-primary);
  background-color: var(--td-white);
  font-size: 0.9rem;
  font-weight: bolder;
}
textarea {
  resize: none;
  height: 75px;
}

.ted-input label {
  position: absolute;
  top: 50%;
  left: 50px;
  transform: translateY(-50%);
  color: var(--td-primary);
  /* background-color: var(--td-white); */
  padding: 0px 5px;
  transition: all 0.5s;
}

.ted-input .select {
  /* border: 1px solid red; */
  position: relative;
  /* border: 0px solid; */
  outline: 0px;

  font-family: var(--td-font-regular);
  flex: 1;
  cursor: pointer;
}
.ted-input .select .bar-select {
  height: 14px;
  padding: 15px 10px;
  cursor: pointer;
  flex: 1;
  display: flex;
  /* justify-content: flex-end; */
  align-items: center;
}
.ted-input .select .bar-select > div:nth-child(1),
.ted-input .select .bar-multi-select > div:nth-child(1) {
  flex: 1;
}
.ted-input .select .select-options {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  display: none;
  z-index: 10;

  /* border: 1px solid red; */
  padding-bottom: 5px;
  background-color: var(--td-white);
  box-shadow: var(--td-box-shadow);
}
.ted-input .select .select-options.show-options {
  display: block;
}

.ted-input .select .select-options ul {
  /* border: 1px solid red; */
  background-color: var(--td-white);
}
.ted-input .select .select-options ul li {
  padding: 14px 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.ted-input .select .select-options ul li span {
  margin-right: 10px;
}
.ted-input .select .select-options ul li:hover,
.ted-input .select .select-options ul li.active {
  background-color: var(--td-gray-dark);
  /* color: var(--td-white); */
}

.ted-input .select .bar-multi-select {
  padding: 15px 10px;
  cursor: pointer;
  flex: 1;
  display: flex;
  align-items: center;
}
.ted-input .select .bar-multi-select .options {
  display: flex;
  flex-wrap: wrap;
}
.ted-input .select .bar-multi-select .options span.options-select {
  background-color: var(--td-gray-dark);
  margin-right: 5px;
  padding: 2px 7px;
  font-size: 0.8rem;
  font-weight: bolder;
  border-radius: var(--td-border-radius);
  color: var(--td-primary);
  margin-bottom: 4px;
}
/* FILE INPUT DESIGN */
.file {
  border: 2px solid var(--td-primary);
  border-radius: var(--td-border-radius);
  margin-top: 10px;
}
.file label {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.file label input[type="file"] {
  display: none;
}
.file label > span:nth-child(3) {
  color: var(--td-primary);
  font-size: 0.9rem;
}

.file label > span:nth-child(3).success {
  color: var(--td-success);
}
.select-options .container-search {
  background-color: var(--td-white);
}
.select-options .container-search .search {
  /* border: 1px solid red; */

  display: flex;
  justify-content: center;
  align-items: center;
  width: 95%;
  margin: 0 auto;
  margin-top: 5px;
  border-radius: 10px;
  background-color: rgba(117, 115, 115, 0.201);
  overflow: hidden;
}
.select-options .container-search .search span {
  padding: 0px 10px;
}
.select-options .container-search .search input {
  /* border: 1px solid blue; */
  font-size: 1rem;
  color: var(--td-primary);
  padding: 20px 0px;
  flex: 1;
  background-color: transparent;
  /* width: 60% !important; */
}
.select-options .container-search .search input::placeholder {
  color: var(--td-primary);
}
.multi-select-ul {
  /* border: 1px solid red; */
  max-height: 280px !important;
  overflow-y: scroll;
}

.checkbox {
  display: flex;
  align-items: center;
  /* margin-top: 10px; */
  color: var(--td-primary);
}
.checkbox .checkbox-btn {
  width: 25px;
  height: 25px;
  border: 2px solid var(--td-primary);

  border-radius: var(--td-border-radius);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
}
.checkbox .checkbox-btn.active {
  background-color: var(--td-primary);
}
@keyframes labelShown {
  /* 0% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(-50px);
  } */
}

/* ##################################### */
/* ##################################### */
/* ##################################### */
/* ######## INPUT STYLE V1 ############# */
/* ##################################### */
/* ##################################### */
/* ##################################### */
.v1 .ted-input,
.v1 .file {
  border: 0px solid var(--td-primary);
  border-radius: var(--td-border-radius_v1);
  background-color: var(--td-gray-dark);
}
.v1 input[type="text"],
.v1 input[type="password"],
.v1 input[type="date"],
.v1 input[type="number"],
.v1 textarea {
  border-radius: var(--td-border-radius_v1);
  background-color: transparent;
}
