/* .modal {
} */
.modal .backdrop {
  background-color: rgba(30, 29, 29, 0.566);
  position: fixed;
  top: 0px;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
}
.modal .container-modal {
  background-color: var(--td-white);
  border-radius: 10px;
  box-shadow: var(--shadow-4);
  padding: 40px;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translate(-50%, 30px);
  display: flex;
  flex-direction: column;
  z-index: 12;
}
/* .modal .container-modal .modal-header {
} */
.modal .container-modal .modal-header h2 {
  font-size: 1.3rem;
  text-align: center;
  padding: 10px 0px;
  font-weight: bolder;
  color: var(--td-primary);
  text-transform: uppercase;
}

.modal .container-modal .modal-container {
  padding: 10px 0px;
}

.modal .container-modal .modal-footer {
  border: 1px solid green;
  padding: 10px 0px;
}
.modal .container-modal.big {
  width: 60%;
}
.modal .container-modal.big-01 {
  width: 50%;
}
.modal .container-modal.moyen {
  width: 40%;
}
.modal .container-modal.moyen-01 {
  width: 30%;
}
.modal .container-modal.small {
  width: 20%;
}

.modal.open .container-modal {
  animation: enter-modal-error 0.5s linear;
}
.modal.close .container-modal {
  /* transform: translate(-50%, -4000px);
  animation: close-modal-error 2s linear; */
  display: none;
}
.modal.open .backdrop {
  display: block;
}
.modal.close .backdrop {
  display: none;
}
.modal .save-btn-modal {
  display: flex;
  width: 150px;
  float: right;
}
.modal .delete-btn-modal {
  width: 150px;
  display: flex;
  margin: 0 auto;
}
.modal .delete-content {
  text-align: center;
}
.modal .delete-content p:nth-child(2) {
  margin-top: 10px;
  color: var(--td-danger);
  font-size: 1.2rem;
  font-weight: bolder;
}

@keyframes enter-modal-error {
  0% {
    transform: translate(-50%, -30px);
  }
  100% {
    transform: translate(-50%, 30px);
  }
}

@keyframes close-modal-error {
  0% {
    transform: translate(-50%, 30px);
  }
  100% {
    transform: translate(-50%, -4000px);
  }
}

/* STYLE FOR SIDE MODAL */
.modal .container-modal.side {
  height: 100vh;
  border-radius: 0px !important;
  left: 0 !important;
  transform: translate(0%, 0px) !important;
}
.modal .container-modal.big.side {
  width: 60% !important;
  left: 35% !important;
}
.modal .container-modal.big-01.side {
  width: 50% !important;
  left: 45% !important;
}
.modal .container-modal.moyen.side {
  width: 40% !important;
  left: 55% !important;
}
.modal .container-modal.moyen-01.side {
  width: 30%;
  left: 65% !important;
}
.modal .container-modal.small.side {
  width: 20% !important;
  left: 75% !important;
}
.modal.open .container-modal.side {
  animation: enter-modal-side-error 0.5s linear !important;
}
/*
.modal.close .container-modal.side {
  
  display: none;
} */

@keyframes enter-modal-side-error {
  0% {
    transform: rotate(120deg);
  }
  100% {
    transform: translateX(0px);
  }
}
