.container-pdf {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  bottom: 0;
  /* max-height: 100vh; */
  /* min-height: 100vh; */
  overflow: auto;
  z-index: 10;
}
.container-pdf .backdrop {
  background-color: rgba(30, 29, 29, 0.566);
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}
.container-pdf .page {
  /* border: 1px solid red; */
  position: fixed;
  top: 10px;
  left: 40px;
  color: var(--td-primary);
  display: flex;
  flex-direction: column;
  width: 400px;
  background-color: var(--td-white);
  padding: 20px 10px;
  box-shadow: var(--td-box-shadow);
  border-radius: var(--td-border-radius);
}
.container-pdf .page span:nth-child(1) {
  font-size: 1.2rem;
  font-weight: bolder;
  margin-bottom: 10px;
}
.container-pdf .close-viewer {
  position: fixed;
  top: 10px;
  right: 400px;
  color: var(--td-primary);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: rgba(30, 29, 29, 0.4);
  /* background-color: var(--td-white); */
  /* padding: 20px 10px; */
  box-shadow: var(--td-box-shadow);
  border-radius: var(--td-border-radius);
  cursor: pointer;
  z-index: 10;
}

.container-pdf div {
}

/* For pdf */
.react-pdf__Document {
  animation: enter-pdf 1s linear;
}
.react-pdf__Page {
  margin-top: 10px;
  padding: 0px;

  position: fixed;
}
.react-pdf__Page__textContent {
  border: 1px solid darkgrey;
  /* box-shadow: 5px 5px 5px 1px #ccc; */
  box-shadow: var(--td-box-shadow);
  border-radius: 5px;
}

.react-pdf__Page__annotations.annotationLayer {
  /* padding: 20px; */
}

.react-pdf__Page__canvas {
  margin: 0 auto;
  /* margin-top: 10px; */
  /* width: 80%; */
}

/* For all pages */

@keyframes enter-pdf {
  0% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(0px);
  }
}
