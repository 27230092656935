@font-face {
  font-family: "ChevinLight";
  src: url("fonts/ChevinLight.otf");
}
/* 
primary: '#145784',
  dark_primary: '#1e3d52',
  secondary: '#eab01c',
  tertiary: '#047857',
  quarto: '#be123c',
  white: '#ffffff',
  black: '#6e6e6e',
  black_0: '#000000', */

:root {
  --td-blue: #1d4ed8;
  --td-indigo: #4338ca;
  --td-purple: #7e22ce;
  --td-pink: #be185d;
  --td-red: #b91c1c;
  --td-orange: #b91c1c;
  --td-yellow: #a16207;
  --td-green: #15803d;
  --td-teal: #0f766e;
  --td-cyan: #0e7490;
  --td-gray: #3f3f46;
  --td-white: #ffffff;
  --td-black: #000000;
  --td-gray-dark: #0000001b;
  --td-primary: #145784;
  --td-secondary: #1e3d52;
  --td-success: #047857;
  --td-info: #0369a1;
  --td-warning: #c2410c;
  --td-danger: #be123c;
  --td-light: #44403c;
  --td-dark: #27272a;
  --td-dark-01: #a83d42;
  --td-dark-02: #a8a8ad7b;
  --td-dark-03: #7a7a7bf3;

  --td-font-regular: "ChevinLight";
  --td-font-bold: "";
  --td-font-light: "";
  --td-font-italic: "";

  --td-border-radius: 5px;
  --td-border-radius_v1: 100px;
  --td-padding-main: 20px;

  --td-box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.2);
  --shadow-1: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  --shadow-2: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --shadow-3: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  --shadow-4: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
  --shadow-5: 10px 20px 25px 5px rgba(0, 0, 0, 0.1),
    10px 10px 10px 5px rgba(0, 0, 0, 0.04);

  /* custom for seed */
  --td-custom-bg-footer: #98979719;
}
html {
  font-size: 14px;
  font-family: var(--td-font-regular), "Courier New", Courier, monospace;
  background-color: var(--td-white);
}
body {
  background-color: var(--td-white);
  color: var(--td-light);
  box-sizing: border-box;
}

img {
  width: 100%;
  height: auto;
}
/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: rgba(18, 18, 192, 0.801) white;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: white;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--td-primary);
  border-radius: 20px;
  border: 3px solid white;
}

.empty-data {
  color: var(--td-danger);
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  padding: 50px 0px;
  /* border: 1px solid red; */
}
/* STATUS */
.status {
  border-radius: 5px;
  font-weight: bolder;
  color: var(--td-white);
  padding: 0.4rem;
  font-size: 0.6rem;
  text-transform: uppercase;
  width: fit-content;
  display: flex;
  /* justify-content: center;
  align-items: center; */
}
.status span {
  display: block;
}
.status.success {
  background-color: var(--td-success);
}
.status.danger {
  background-color: var(--td-danger);
}
.status.warning {
  background-color: var(--td-warning);
}

/* #### IMPORT CSS COMPONENT FILES ###### */
@import url("typographie.css");
@import url("animation.css");
@import url("skeleton.css");
@import url("table.css");
