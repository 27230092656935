.dashboard h2 {
  text-align: center;
  font-size: 1.4rem;
  color: var(--td-primary);
}
.cards-container {
  display: flex;
  margin-top: 30px;
  flex-wrap: wrap;
  justify-content: center;
}
.cards {
  /* border: 1px solid red; */
  box-sizing: border-box;
  padding: 40px;
  flex: 0 1 calc(25% - 10px);
  box-shadow: var(--td-box-shadow);
  border-radius: 5px;
  border-left: 10px solid var(--td-primary);
  margin-top: 10px;
  margin-left: 10px;
}
.cards:not(:first-child) {
}
.cards > div:nth-child(1) span {
  font-size: 2.5rem;
  font-weight: bolder;
  color: var(--td-primary);
}
.cards > div:nth-child(2) span {
  font-size: 1.2rem;
  font-weight: bolder;
  color: #a6a6abcc;
}
