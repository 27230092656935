.filter-interval {
  /* border: 1px solid red; */
  display: flex;
  flex-direction: row;
  align-items: center;
  /* width: 400px; */
  z-index: -1;
}

.filter-interval .form-filter {
  /* border: 1px solid red; */
  width: 50%;
}
