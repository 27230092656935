.table {
  margin-top: 20px;
  padding: 0px 0px;
  box-shadow: var(--td-box-shadow);
  border-radius: 10px;
  border-top: 4px solid var(--td-success);
}
.table .tabe-content {
  display: flex;
  padding: 14px 15px;
  justify-content: baseline;
}

.table .tabe-content > div {
  /* flex: 1; */
  display: flex;
  align-items: center;
  /* border: 1px solid red; */
}
.table > div:nth-child(1) {
  color: var(--td-success);
  background-color: rgba(146, 145, 145, 0.3);
  cursor: pointer;
}

.table > div:not(:nth-child(1)):hover {
  background-color: rgba(146, 145, 145, 0.05);
  cursor: pointer;
}
.table .tabe-content > div figure {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}
.table .tabe-content > div figure img {
  width: 100%;
}

.table .table-loader {
  /* border: 1px solid red; */
  position: relative;
  /* min-height: 100px; */
  padding: 100px 0px;
}
.table .action-btn {
  background-color: var(--td-primary);
  border-radius: var(--td-border-radius);
  display: flex;
  overflow: hidden;
}
.table .action-btn a {
  text-decoration: none;
  color: var(--td-white);
  font-size: 0.9rem;
  /* border: 1px solid red; */
  padding: 10px;
}
.table .action-btn :hover {
  opacity: 0.5;
  transition: all 1s;
}
.table .status {
  background-color: var(--td-success);
  color: var(--td-white);
  font-size: 0.7rem;
  padding: 7px 10px;
  border-radius: var(--td-border-radius);
}

.table .status.warning {
  background-color: var(--td-warning);
}

.table .status.info {
  background-color: var(--td-info);
}

.table .status.success {
  background-color: var(--td-success);
}

.table .status.deleted {
  background-color: var(--td-danger);
}
/* style flex pour elements  configurer*/
.table .f01 {
  flex: 1 !important;
}
.table .f02 {
  flex: 2 !important;
}
.table .f03 {
  flex: 3 !important;
}
.table .f04 {
  flex: 4 !important;
}
.table .f05 {
  flex: 5 !important;
}
.table .f06 {
  flex: 6 !important;
}
.table .f07 {
  flex: 7 !important;
}
.table .f08 {
  flex: 8 !important;
}
.table .f09 {
  flex: 9 !important;
}
.table .f10 {
  flex: 10 !important;
}
.table .c01 {
  /* flex: 1 1 -20px !important; */
  /* border: 1px solid red; */
  /* width: 100px; */
  display: flex;
  justify-content: center;
}
